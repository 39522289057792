











































import { Component, Vue } from "vue-property-decorator";
// @ is an alias to /src

@Component({
  components: {},
})
export default class AboutUs extends Vue {}
